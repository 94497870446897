/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  enterInfo: {
    "current_state": {
      "revenue": 420000000,
      "contract": 1542,
      "customer": 6800,
      "staff": 512
    },
    "top_product": [
      {
        "id": 1,
        "product_name": "Health insurance",
        "revenue": "35000000",
        "quantity": 160
      },{
        "id": 2,
        "product_name": "Home insurance",
        "revenue": "50000000",
        "quantity": 170
      },{
        "id": 3,
        "product_name": "Car insurance",
        "revenue": "150000",
        "quantity": 90
      },
    ],
    "account": {
      "last_week": [
        {
          "time_day": "2024-11-25",
          "amount": "35000000"
        },{
          "time_day": "2024-11-26",
          "amount": "12000000"
        },{
          "time_day": "2024-11-27",
          "amount": "45000000"
        },{
          "time_day": "2024-11-28",
          "amount": "22000000"
        },{
          "time_day": "2024-11-29",
          "amount": "30000000"
        },{
          "time_day": "2024-11-30",
          "amount": "36000000"
        },{
          "time_day": "2024-12-01",
          "amount": "39000000"
        }
      ],
      "this_week": [
        {
          "time_day": "2024-12-02",
          "amount": "20000000"
        },{
          "time_day": "2024-12-03",
          "amount": "45000000"
        },{
          "time_day": "2024-12-04",
          "amount": "28000000"
        },{
          "time_day": "2024-12-05",
          "amount": "37000000"
        },{
          "time_day": "2024-12-06",
          "amount": "16000000"
        },{
          "time_day": "2024-12-07",
          "amount": "21000000"
        },{
          "time_day": "2024-12-08",
          "amount": "36000000"
        }
      ]
    },
    "analystic": [
      {
        "time_day": "2024-12-02",
        "amount": "10000000"
      },{
        "time_day": "2024-12-03",
        "amount": "45000000"
      },{
        "time_day": "2024-12-04",
        "amount": "28000000"
      },{
        "time_day": "2024-12-05",
        "amount": "37000000"
      },{
        "time_day": "2024-12-06",
        "amount": "16000000"
      },{
        "time_day": "2024-12-07",
        "amount": "21000000"
      },{
        "time_day": "2024-12-08",
        "amount": "36000000"
      }
    ],
  },
}
