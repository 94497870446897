/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js"

const actions = {
  uploadFile(context, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
      frm.append("file",  payload.file)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/exam_subject_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadBookFile(context, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
    frm.append("file",  payload.file)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/textbook_exam_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadAudioFile({
    commit
  }, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("file",  payload.file)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/english_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_AUDIO_FILE', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadSchoolFile(context, payload) {
    var frm = new FormData()
    frm.append("id",  payload.id)
    frm.append("type",  payload.type)
      frm.append("file",  payload.file)
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/school_subject_file" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFile({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject_file" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_ACTIVE_FILE', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFile({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/exam_subject_file" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_ACTIVE_FILE', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  requestProjectDetail(context, payload) {
    return new Promise((resolve, reject) => {
      var frm = new FormData()
      for(var key of Object.keys(payload)) {
        if(key == 'file' || key == 'type') {
          for(var i in payload[key]) {
            frm.append(key,  payload[key][i])
          }
        } else {
          frm.append(key,  payload[key])
        }
      }
      axios.post("/api/consult_info" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getProjectRequestList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/consult_info?start_time=" + payload.start_time + '&end_time=' + payload.end_time)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_REQUEST_LIST', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadFile(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`https://www.yellowhippy.kr/consult_file/${payload.id}/${payload.name}`, {
          responseType: 'blob'
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', payload.name); // 파일명만 설정
          document.body.appendChild(link);
          link.click();

          // 링크 제거
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
      }).catch((error) => {
        reject(error)
      })
    })
  },
  hideAndDeleteAlert({ commit }, idx) {
    commit('SET_IS_HIDE', idx);
    setTimeout(() => {
      commit('DELATE_ALERT', idx);
    }, 300);
  }
}

export default actions
