/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleCrmState.js'
import mutations from './moduleCrmMutations.js'
import actions from './moduleCrmActions.js'
import getters from './moduleCrmGetters.js'

export default {
		namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
