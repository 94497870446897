<template>
    <div ref="mqtWrap" class="mqt" :class="{'mqt--reverse': reverse}">
      <div :class="`mqt__style-${this.scopeId}`"></div>
      <div class="mqt__scroller" ref="scroller">
        <div class="mqt__item-wrap" ref="itemWrap" :style="wrapStyle">
          <!-- 아이템을 반복적으로 렌더링합니다 -->
          <div :class="`mqt__item-${scopeId}`" class="mqt__item" ref="mqtItem" v-for="i in itemCount" :key="i" :style="itemStyle">
            <slot></slot> <!-- 부모 컴포넌트로부터 전달된 콘텐츠가 이곳에 들어갑니다 -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { gsap } from "gsap";
  
  export default {
    props: {
      reverse: {
        type: Boolean,
        default: false,
      },
      direction: {
        type: String,
        default: 'down',
      },
      firstDelay: {
        type: Number,
        default: 0,
      },
      timeScale: {
        type: Number,
        default: 1,
      },
      gap: {
        type: Number,
        default: 30,
      },
      duration: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        itemCount: 4,
        itemWidth: 0,
        tl: null, // GSAP 타임라인
        scopeId: Math.random().toString(36).substring(2, 9),
        calcedDuration: 0,
        selfSlideAmount: 50,
        scrollMoveAmount: 150,
        scrollPosition: -300
      }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        scrollY() {
            return this.$store.state.scrollY
        },
        deltaY() {
            return this.$store.state.deltaY
        },
        innerWidth() {
            return this.$store.state.innerWidth
        },
        innerHeight() {
            return this.$store.state.innerHeight
        },
        wrapStyle() {
            return {
            animationDuration: `${this.calcedDuration}s` // 애니메이션 지속 시간 설정
        };
        },
        itemStyle() {
            return {
                padding: `0 ${this.gap / 2}px`
            };
        }
    },
    watch: {
        direction() {
            this.changeScrollDirection()
        },
        scrollY() {
            if(!this.isMobile) {
                this.moveMqtScroller()
            }
        },
    },
    methods: {
        setScrollTrigger() {
            let start, end;
            const scroller = this.$refs.scroller;

            if(this.innerHeight > this.$refs.mqtWrap.getBoundingClientRect().top) {
                start = e => stPos(e, 0, 0);
                end = () => this.innerHeight + this.$refs.mqtWrap.getBoundingClientRect().height;
            } else {
                start = e => stPos(e, 0, 1);
                end = e => stPos(e, 1, 0);
            }
  
            this.tl = gsap.timeline({
				scrollTrigger: {
					start,
					end,
					scrub: true,
				}
			});
            this.tl.to(scroller, {
                x: () => this.reverse == true ? this.scrollMoveAmount : -this.scrollMoveAmount
            });
        },
        calcItemCount() {
            setTimeout(() => {
                var containerWidth = this.innerWidth + this.scrollMoveAmount;
                const itemWrap = this.$refs.itemWrap;
        
                if(!document.querySelector(`.mqt__item-${this.scopeId}`)) return;
        
                this.itemWidth = document.querySelector(`.mqt__item-${this.scopeId}`).clientWidth;
                this.itemCount = Math.floor(containerWidth / this.itemWidth) + 2;
                console.log(this.itemCount)
        
                // 시작 클래스 추가, css 변수 설정
                itemWrap.style.setProperty('--itemWidth', this.itemWidth);
        
                // 키프레임 애니메이션 생성 (self-to-left와 self-to-right)
                const keyframes = `
                    <style = "text/css">
                        @keyframes self-to-left-${this.scopeId} {
                            0% { transform: translate3d(0, 0, 0); }
                            100% { transform: translate3d(-${this.itemWidth}px, 0, 0); }
                        }
                        @keyframes self-to-left-reverse-${this.scopeId} {
							0% { transform: translate3d(-${this.itemWidth}px, 0, 0) }
							100% { transform: translate3d(0, 0, 0) }
						}
                        @keyframes self-to-right-${this.scopeId} {
                            0% { transform: translate3d(0, 0, 0); }
                            100% { transform: translate3d(${this.itemWidth}px, 0, 0); }
                        }
                        @keyframes self-to-right-reverse-${this.scopeId} {
							0% { transform: translate3d(${this.itemWidth}px, 0, 0) }
							100% { transform: translate3d(0, 0, 0) }
						}
                    <style>
                `;
                
                document.querySelector(`.mqt__style-${this.scopeId}`).innerHTML = keyframes
        
                this.calcedDuration = (this.itemWidth / this.selfSlideAmount) * this.timeScale
                itemWrap.style.setProperty("animation-duration", `${this.calcedDuration}s`);
        
                if(this.reverse) {
                    if(this.direction == 'down') {
                        itemWrap.style.setProperty("animation-name", `self-to-right-${this.scopeId}`);
                    } else {
                        itemWrap.style.setProperty("animation-name", `self-to-right-reverse-${this.scopeId}`);
                    }
                } else {
                    if(this.direction == 'down') {
                        itemWrap.style.setProperty("animation-name", `self-to-left-${this.scopeId}`);
                    } else {
                        itemWrap.style.setProperty("animation-name", `self-to-left-reverse-${this.scopeId}`);
                    }
                }
                this.firstDelay && itemWrap.style.setProperty("animation-delay", `${this.firstDelay}s`);
        
                if(!this.tl) {
                    this.setScrollTrigger()
                }
            });
        },
        changeScrollDirection() {
            const itemWrap = this.$refs.itemWrap;
            const scroller = this.$refs.scroller;
            const currentProgress = Math.abs(itemWrap.getBoundingClientRect().x - scroller.getBoundingClientRect().x) / this.itemWidth;
            if (this.reverse) {
                if (this.direction == 'down') {
                    const calcedDelay = -(currentProgress * this.calcedDuration);
                    itemWrap.style.setProperty("animation-delay", `${calcedDelay}s`);
                    itemWrap.style.setProperty("animation-name", `self-to-right-${this.scopeId}`);
                } else {
                    const calcedDelay = (currentProgress * this.calcedDuration) - this.calcedDuration;
                    itemWrap.style.setProperty("animation-delay", `${calcedDelay}s`);
                    itemWrap.style.setProperty("animation-name", `self-to-right-reverse-${this.scopeId}`);
                }
            } else {
                if (this.direction == 'down') {
                    const calcedDelay = -(currentProgress * this.calcedDuration);
                    itemWrap.style.setProperty("animation-delay", `${calcedDelay}s`);
                    itemWrap.style.setProperty("animation-name", `self-to-left-${this.scopeId}`);
                } else {
                    const calcedDelay = (currentProgress * this.calcedDuration) - this.calcedDuration;
                    itemWrap.style.setProperty("animation-delay", `${calcedDelay}s`);
                    itemWrap.style.setProperty("animation-name", `self-to-left-reverse-${this.scopeId}`);
                }
            }
        },
        moveMqtScroller() {
            const target = this.$refs.mqtWrap;
            const rect = target.getBoundingClientRect();

            // 스크롤이 조건에 들어오는지 확인
            if ((this.innerHeight > rect.top) && (rect.top > 0)) {
                // 스크롤에 따른 이동 비율 계산 (0~100% 범위로 제한)
                let scrollRatio = Math.abs((this.innerHeight - rect.top) / this.innerHeight);
                scrollRatio = Math.min(1, Math.max(0, scrollRatio)); // 값이 0~100%로 제한됨

                // 비율에 따라 이동할 거리 계산, -300px에서 0px 사이로 제한
                const distance = Math.round(this.scrollMoveAmount * scrollRatio); // 최대 300px까지 이동

                // 스크롤 포지션 업데이트
                this.scrollPosition = this.reverse ? (-this.scrollMoveAmount + distance) : -distance

                // 스크롤 포지션을 적용
                this.$refs.scroller.style.transform = `translate3d(${this.scrollPosition}px, 0, 0)`;
            }
        }
    },
    mounted() {
        if(document.readyState != "complete") {
            window.addEventListener("load", () => {
                if(this.$refs.scroller) {
                    this.$refs.scroller.style.transform = `translate3d(-300px, 0, 0)`;
                    this.calcItemCount();
                }
            }, { once: true });
        }
        else {
            this.calcItemCount();
        }
        window.addEventListener("resize", this.calcItemCount); // 윈도우 크기 변경시 재계산
    },
    beforeDestroy() {
        if (this.tl) {
            gsap.killTweensOf(this.tl);
            this.tl.scrollTrigger && tl.scrollTrigger.kill();
            this.tl.kill(); // GSAP 타임라인 제거
        }
        window.removeEventListener("resize", this.calcItemCount);
    }
  };
  </script>
  
  <style scoped lang="scss">
  .mqt {
    position: relative;
    z-index: 30;
    &__item-wrap {
        position: relative;
        display: flex;
        white-space: nowrap;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: 30;
    }
    &__item {
      display: inline-flex;
      will-change: transform;
    }
    &--reverse .mqt__item-wrap {
        flex-direction: row-reverse;
    }
  }
  </style>
  