<template>
    <div class="splash" :style="{width: isSplash ? '100%' : '0vh'}" style="position: fixed; top: 0; left: 0; height: var(--app-height); z-index: 998; background: #201c20; transition: width 0.6s ease; overflow: hidden; z-index: 999999;">
        <div :style="{justifyContent: (!pageTitle || (pageTitle && pageTitle == true)) ? 'flex-start' : 'center'}" style="position: relative; width: 100%; height: var(--app-height); display: flex; flex-direction: column; align-items: center; width: 100vw">
            <div v-if="pageTitle && (pageTitle != true)" style="white-space: nowrap;">
                <div class="fixed-content" style="width: 100%; min-height: 60px; display: flex; align-items: center; justify-content: center; animation: none">
                    <div class="t--title" ref="title" style="transition: all 0.5s ease; letter-spacing: -0.07em; color: white; text-align: center">
                        <h1 style="text-transform: uppercase; font-weight: 500; line-height: 0.8; letter-spacing: -0.1em;">{{pageTitle}}</h1>
                    </div>
                </div>
            </div>
            <div v-if="isSplash == true" style="position: relative; width: 100%; height: var(--app-height); display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div :style="{
                        paddingTop: !isMobile ? '30px' : '15px'
                    }" 
                    style="height: 60px;">
                    <Marquee class="t--kor" 
                        :direction="Math.sign(deltaY) == 1 ? 'down' : 'up'" 
                        :gap="50" 
                        :timeScale="0.6"
                        :reverse="false">
                        <template v-slot:default>
                            <div class="t--700 t--kor" 
                                :style="{color: 'white'}"
                                style="display: flex; align-items: center; font-weight: 600; text-align: center;">
                                <p style="line-height: 1">WE C</p>
                                <div :style="{
                                        animationName: Math.sign(deltaY) == 1 ? 'rotate-left' : 'rotate-right',
                                        height: !isMobile ? '40px' : '20px'
                                    }" 
                                    style="line-height: 1; animation-duration: 1s; animation-timing-function: linear; animation-iteration-count: infinite;">
                                    <img src="@/assets/images/official/icon/star_white.png" alt="기업 로고 아이콘" 
                                        :style="{
                                            width: !isMobile ? '40px' : '20px'
                                        }">
                                </div>
                                <p style="line-height: 1">DE, YOU EXCEL™</p>
                            </div>
                        </template>
                    </Marquee>
                </div>
                <div :style="{
                    padding: !isMobile ? '60px 50px 124px 50px' : '0 20px 60px 20px'
                }" 
                style="width: 100%; flex: 1 0 auto; display: flex; flex-direction: column; align-items: flex-end; justify-content: flex-start;">
                    <div :style="{
                            padding: !isMobile ? '20px 0' : '10px 0'
                        }" 
                        style="width: 100%; display: flex; align-items: flex-start; justify-content: space-between; z-index: 2;">
                        <p class="t--500" style="color: white; line-height: 1.3; font-weight: 600">
                            BEHAVIORS<br> 
                            SHAPE<br> 
                            TOMORROW™
                        </p>
                        <p class="t--400" style="color: white; line-height: 1.1; text-align: right;">
                            Est. Sep<br>©2K23
                        </p>
                    </div>
                    <div style="height: 8em">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Marquee from '@/components/widget/Marquee.vue'
export default {
    data() {
        return {
            pageTitle: null,
            slideItems: [1, 2, 3],
        }
    },
    components: {
        Marquee
    },
    watch: {
        isSplash() {
            if(this.isSplash) {
                this.pageTitle = this.isSplash
            } else {
                setTimeout(() => {
                    this.pageTitle = null
                }, 500);
            }
            console.log(this.isSplash)
        }
    },
    computed: {
        innerWidth() {
            return this.$store.state.innerWidth
        },
        isSplash() {
            return this.$store.state.isSplash
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        deltaY() {
            return this.$store.state.deltaY
        }
    },
    methods: {
        moveFirstItemToLast() {
            const firstItem = this.slideItems.shift();
            this.slideItems.push(firstItem);
        },
    }
}
</script>
<style lang="scss">
    .splash {
        @media screen and (max-width: 1920px) {
            .telemark {
                font-size: 1.3em;
            }
        }
        @media screen and (max-width: 768px) {
            .telemark {
                font-size: 1.1em;
            }
        }
    }
</style>