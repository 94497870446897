var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"splash",staticStyle:{"position":"fixed","top":"0","left":"0","height":"var(--app-height)","z-index":"999999","background":"#201c20","transition":"width 0.6s ease","overflow":"hidden"},style:({width: _vm.isSplash ? '100%' : '0vh'})},[_c('div',{staticStyle:{"position":"relative","width":"100vw","height":"var(--app-height)","display":"flex","flex-direction":"column","align-items":"center"},style:({justifyContent: (!_vm.pageTitle || (_vm.pageTitle && _vm.pageTitle == true)) ? 'flex-start' : 'center'})},[(_vm.pageTitle && (_vm.pageTitle != true))?_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"fixed-content",staticStyle:{"width":"100%","min-height":"60px","display":"flex","align-items":"center","justify-content":"center","animation":"none"}},[_c('div',{ref:"title",staticClass:"t--title",staticStyle:{"transition":"all 0.5s ease","letter-spacing":"-0.07em","color":"white","text-align":"center"}},[_c('h1',{staticStyle:{"text-transform":"uppercase","font-weight":"500","line-height":"0.8","letter-spacing":"-0.1em"}},[_vm._v(_vm._s(_vm.pageTitle))])])])]):_vm._e(),(_vm.isSplash == true)?_c('div',{staticStyle:{"position":"relative","width":"100%","height":"var(--app-height)","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('div',{staticStyle:{"height":"60px"},style:({
                    paddingTop: !_vm.isMobile ? '30px' : '15px'
                })},[_c('Marquee',{staticClass:"t--kor",attrs:{"direction":Math.sign(_vm.deltaY) == 1 ? 'down' : 'up',"gap":50,"timeScale":0.6,"reverse":false},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"t--700 t--kor",staticStyle:{"display":"flex","align-items":"center","font-weight":"600","text-align":"center"},style:({color: 'white'})},[_c('p',{staticStyle:{"line-height":"1"}},[_vm._v("WE C")]),_c('div',{staticStyle:{"line-height":"1","animation-duration":"1s","animation-timing-function":"linear","animation-iteration-count":"infinite"},style:({
                                    animationName: Math.sign(_vm.deltaY) == 1 ? 'rotate-left' : 'rotate-right',
                                    height: !_vm.isMobile ? '40px' : '20px'
                                })},[_c('img',{style:({
                                        width: !_vm.isMobile ? '40px' : '20px'
                                    }),attrs:{"src":require("@/assets/images/official/icon/star_white.png"),"alt":"기업 로고 아이콘"}})]),_c('p',{staticStyle:{"line-height":"1"}},[_vm._v("DE, YOU EXCEL™")])])]},proxy:true}],null,false,2964464367)})],1),_c('div',{staticStyle:{"width":"100%","flex":"1 0 auto","display":"flex","flex-direction":"column","align-items":"flex-end","justify-content":"flex-start"},style:({
                padding: !_vm.isMobile ? '60px 50px 124px 50px' : '0 20px 60px 20px'
            })},[_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"flex-start","justify-content":"space-between","z-index":"2"},style:({
                        padding: !_vm.isMobile ? '20px 0' : '10px 0'
                    })},[_vm._m(0),_vm._m(1)]),_c('div',{staticStyle:{"height":"8em"}})])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"t--500",staticStyle:{"color":"white","line-height":"1.3","font-weight":"600"}},[_vm._v(" BEHAVIORS"),_c('br'),_vm._v(" SHAPE"),_c('br'),_vm._v(" TOMORROW™ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"t--400",staticStyle:{"color":"white","line-height":"1.1","text-align":"right"}},[_vm._v(" Est. Sep"),_c('br'),_vm._v("©2K23 ")])
}]

export { render, staticRenderFns }